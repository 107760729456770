/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_metronic/partials/widgets'
import {toAbsoluteUrl} from "../../../_metronic/helpers";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";

const DashboardPage: FC = () => {
  let user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  let userData = JSON.parse(user.data)
  return (
    <div>


      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-12 p-10 text-center'>
          <h1>CÔNG TY CỔ PHẦN CÔNG NGHỆ VÀ PHÁT TRIỂN ANAN</h1>
          <a href="https://www.robotanan.com/">www.robotanan.com</a>
          <br/>
          <img className='m-10 w-150px' src={toAbsoluteUrl('/media/logos/logo-anan.png')} alt='logo-anan' />
          <br/>
          <h2>TÀI KHOẢN NHÀ ĐẦU TƯ DỰ ÁN TRÍ TUỆ NHÂN TẠO ANAN</h2>
          <h4>Họ và tên: {userData.name}</h4>
          <h4>CCCD: {userData.cccd}</h4>
          <h4>Địa chỉ: {userData.address}</h4>
          <h4>SĐT: {userData.phone}</h4>
          <h4>Gói đầu tư: {userData.invest}</h4>
          <h4>Số lượng tài khoản: {userData.accountNumber}</h4>
          <h4>Trong đó:</h4>
          <h4>- Có gia hạn: {userData.expireAccount}</h4>
          <h4>- Trọn đời: {userData.longAccount}</h4>
          <h4>Những ưu đãi kèm theo:</h4>
          <h4>{userData.profit}</h4>
          <h4>Người thừa kế hợp đồng nhà đầu tư: {userData.inherit}</h4>
        </div>
      </div>
      <div className='row gy-5 g-xl-8 d-none'>
        <div className='col-xxl-4'>
          <MixedWidget10
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
          />
          <MixedWidget11
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='175px'
          />
        </div>
        <div className='col-xxl-4'>
          <MixedWidget2
            className='card-xl-stretch mb-xl-8'
            chartColor='danger'
            chartHeight='200px'
            strokeColor='#cb1e46'
          />
        </div>
        <div className='col-xxl-4'>
          <ListsWidget5 className='card-xxl-stretch' />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8 d-none'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8 d-none'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
          {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
        </div>
      </div>
      {/* end::Row */}

      <div className='row g-5 gx-xxl-8 d-none'>
        <div className='col-xxl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        <div className='col-xxl-8'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div>
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <div

    >
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </div>
  )
}

export {DashboardWrapper}
