import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/get-user`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  console.log({email, password})
  // return axios.post(LOGIN_URL, {email, password})
  return axios.post('https://ndt.quandm.xyz/wp-json/auth/login', {
    phone: email,
    password: password
  }).then(response => {
    return response
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken(accessToken: any) {
  return axios.post('https://ndt.quandm.xyz/wp-json/auth/info', {
    accessToken: accessToken
  }).then(response => {
    return response
  })
}
